import React from 'react'
import Layout from '../components/Default/Layout'

const DekujemeVam = () => {
  return (
    <Layout>
      <div className="DekujemeVam">
        <h1>Děkujeme za odběr newsletteru</h1>
        <a href="/" className="Button">
          Úvodní stránka
        </a>
      </div>
    </Layout>
  )
}

export default DekujemeVam
